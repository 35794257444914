import React from "react";
import Link from "next/link";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {FormattedMessage} from "react-intl";

const SocialLinks = dynamic(() => import("../shared/SocialLinks"))

interface IProps {
    pageProps: any
}

export default function FooterContacts({pageProps}: IProps) {
    const site_locale = useSelector((state: IState) => state.locale.code);
    const phone = pageProps.dispatches?.setCoreConfigs.general_info_general_phone?.value;
    const email = pageProps.dispatches?.setCoreConfigs.general_info_general_footer_email?.value;
    const address = pageProps.dispatches?.setCoreConfigs.general_info_general_footer_address?.find((el: { locale_code: string }) => el.locale_code === site_locale)?.value;
    /*TODO fix this locale in server*/
    // const address = pageProps.dispatches.setCoreConfigs.general_info_general_footer_address[0].value;

    const contacts = (
        <ul className="footer-contacts__contacts">
            <li className="contactsAddress">
                {email ? (
                    <p dangerouslySetInnerHTML={{__html: email}}/>
                ) : (
                    ""
                )}
            </li>
            <li className="contactsAddress middleAddress">
                {phone ? (
                    <Link href={`tel:${phone}`}>
                        <a>{phone}</a>
                    </Link>
                ) : (
                    ""
                )}
            </li>
            <li className="contactsAddress">
                {address ? (
                    <p dangerouslySetInnerHTML={{__html: address}}/>
                ) : (
                    ""
                )}
            </li>
            <li>
                <SocialLinks
                    className="footer-newsletter__social-links"
                    shape="circle"
                />
            </li>
        </ul>
    );

    return <div className="site-footer__widget footer-contacts">
        <h3 className="footer-newsletter__title">
            <FormattedMessage
                id="footer.contacts"
                defaultMessage="Contacts"
            />
        </h3>
        {contacts}
    </div>;

}
